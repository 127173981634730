import request from '@/utils/request'


export function listApi(data) {
    return request({
        url: '/advert/des/AppDayProfit/listDes',
        method: 'post',
        data
    })
}

export function listSearch(data) {
    return request({
        url: '/advert/des/AppDayProfit/search',
        method: 'post',
        data
    })
}

export function listAppApi(data) {
    return request({
        url: '/advert/des/profit/listAppDesApi',
        method: 'post',
        data
    })
}

export function listAdApi(data) {
    return request({
        url: '/advert/des/profit/listAdDesApi',
        method: 'post',
        data
    })
}

export function checkDelay(data) {
    return request({
        url: '/advert/des/AppDayProfit/checkDelay',
        method: 'post',
        data
    })
}

