<template>
  <div id="components-form-demo-advanced-search">
    <a-form class="ant-advanced-search-form" :model="listQuery">
      <a-row :gutter="24" style="display:flex;align-items:center">

        <a-col key="1" span="7">
          <a-form-item label="APP">

            <a-select  v-model="listQuery.appId" placeholder="请选择" @change="handleSelectChange">

              <a-select-option v-for="d in body" :key="d.id" :value="d.id" >
                {{ d.name }}
              </a-select-option>

            </a-select>

          </a-form-item>
        </a-col>

        <a-col key="2" span="7">
          <a-form-item label="广告位">

            <a-select v-model="listQuery.adId" placeholder="请选择" allowClear @change="adChange">

              <a-select-option v-for="d in adData" :key="d.id" :value="d.id" >
                {{ d.adName }}
              </a-select-option>

            </a-select>

          </a-form-item>
        </a-col>

        <a-col key="3" span="5">
          <a-form-item label="日期">

            <a-range-picker @change="onChange" />
          </a-form-item>
        </a-col>

        <a-col :style="{ textAlign: 'right',paddingTop: '10px' }">
          <a-button type="primary" @click="handleSearch">查询</a-button>
<!--          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">清除</a-button>-->
        </a-col>

      </a-row>

     
    </a-form>
    <div style="margin:15px 0 5px 0;color:#f00">
       <span v-if="statusText">* {{statusText}}</span>
    </div>
    <div class="search-result-list">
      <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="pagination" >
          
      </a-table>
      <br />
      <a-pagination
          :current="listQuery.current"
          :total="page.total"
          :defaultPageSize="listQuery.size"
          :show-total="total => `共 ${total} 条`"
          @change="affiliatedTableTableChangePage"
      />
      <br />
    </div>
  </div>
</template>
<script>
const columns1 = [

  { title: 'APP', dataIndex: 'appName', key: 'appName',width: 100, align:"center" },
  // { title: '广告位', dataIndex: 'adName', key: 'adName',width: 100, },
  { title: '日期', dataIndex: 'dateYmd', key: 'dateYmd', width: 150, align:"center" },
  // { title: '日活', dataIndex: 'activity', key: 'askNumber', width: 150, align:"center" },
  { title: '收入', dataIndex: 'showRevenue', key: 'showRevenue', width: 150, align:"center" },
  { title: 'ecpm', dataIndex: 'ecpm', key: 'ecpm', width: 150, align:"center" },
  // { title: '请求量', dataIndex: 'askNumber', key: 'askNumber', width: 150 },
  { title: '曝光量', dataIndex: 'exposureNumber', key: 'exposureNumber', width: 150, align:"center" },
  { title: '点击量', dataIndex: 'clickNumber', key: 'clickNumber', width: 150, align:"center" },
  { title: '点击率', dataIndex: 'clickRate', key: 'clickRate', width: 150, align:"center" },
];
const columns2 = [

  { title: 'APP', dataIndex: 'appName', key: 'appName',width: 100, align:"center" },
  { title: '广告位', dataIndex: 'adName', key: 'adName',width: 100, align:"center" },
  { title: '日期', dataIndex: 'dateYmd', key: 'dateYmd', width: 150, align:"center" },
  //  { title: '日活', dataIndex: 'activity', key: 'askNumber', width: 150, align:"center" },
  { title: '收入', dataIndex: 'showRevenue', key: 'showRevenue', width: 150, align:"center" },
  { title: 'ecpm', dataIndex: 'ecpm', key: 'ecpm', width: 150, align:"center" },
  // { title: '请求量', dataIndex: 'askNumber', key: 'askNumber', width: 150 },
  { title: '曝光量', dataIndex: 'exposureNumber', key: 'exposureNumber', width: 150, align:"center" },
  { title: '点击量', dataIndex: 'clickNumber', key: 'clickNumber', width: 150, align:"center" },
  { title: '点击率', dataIndex: 'clickRate', key: 'clickRate', width: 150, align:"center" },
];
import { listApi ,listAppApi,listAdApi ,checkDelay ,listSearch } from '@/api/dayprofit'
import { removeToken } from '@/utils/TokenCache'

export default {
  name: "dayprofit",
  data() {
    return {
      body:[],
      adData:[],
      listQuery: {
        current: 1,
        size: 5,
        appId:'',
        adId:'',
        sdate:'',
        edate:'',
        //2022.09.09添加 -Seri
        customerId: '', //客户id
        sysType: 1 //系统类型。客户系统默认一种类型，运营系统录入数据时，与该处保持一致
      },
      page:{
        total:0,
      },
      form: this.$form.createForm(this),
      data:[],
      columns:columns1,
      pagination:false,
      statusText:''
    };
  },
  inject: ['reload'],
  created() {
    this.initappData();
  },
  methods: {
    /*initappData(){
      let unameDes = sessionStorage.getItem('unameDes')
      // console.log(unameDes)
      this.reload(true)
      listAppApi({'customerId':localStorage.getItem('customer_id')}).then(response => {
        this.body = response.body
        console.error("客户系统 查询账号下所有应用网络请求成功，收到服务器响应：" + this.body)
        let name = this.body[0].name;
        console.error("客户系统 查询账号下所有应用, unameDes: " + unameDes + ",name: " + name)
        if(this.body && unameDes === this.body[0].name){
          this.listQuery.appId = this.body[0].id
          this.handleSelectChange(this.listQuery.appId)
          this.initTableData();
        }
        if(unameDes === !this.body[0].name) {
          this.$message.error('服务器异常');
          setTimeout(()=>{
            removeToken()
            sessionStorage.removeItem("unameDes")
            this.$router.push({path:'/' });
          },2000)
        }
      })
    },*/
    // 更改登录成功后，获取账号应用列表停滞bug 2022.09.08 -Seri
    initappData(){
      this.reload(true)
      let customerId = localStorage.getItem('customer_id');
      // console.error("当前客户id: " + customerId);
      if(!customerId){
        this.requestFail('数据缺失，请刷新页面，重新登录重试');
        return;
      }
      this.listQuery.customerId = customerId;
      listAppApi({'customerId': customerId}).then(response => {
        if(!response){
          this.requestFail('服务器无返回数据')
          return;
        }
        this.body = response.body
        // console.error("客户系统 查询账号下所有应用网络请求成功，收到服务器响应：" + this.body)
        if(!this.body){
          this.requestFail('应用列表查询结果为空')
          return;
        }
        /*let name = this.body[0].name;
        // console.error("客户系统 查询账号下所有应用, name: " + name)*/
        this.listQuery.appId = this.body[0].id
        if(!this.listQuery.appId){
          this.requestFail('数据出错 请重新登录')
          return;
        }
        this.handleSelectChange(this.listQuery.appId)
        this.initTableData();

      })
    },
    /**
     * 2022.09.09 添加  -Seri
     * 请求失败处理逻辑方法
     * @param errorMsg  错误提示信息
     * */
    requestFail(errorMsg){
      this.reload(false);
      this.$message.error(errorMsg);
    },

    /**
     * 客户系统，查询按钮
     * */
    handleSearch() {
      // this.reload(true)
      this.listQuery.current = 1;
      //进行日收益查询，客户id不允许为空！
      if(!this.listQuery.customerId){
          this.requestFail('客户数据异常缺失，无法进行查询');
          return;
      }
      this.tableData();
    },
    adChange(e) {
      // if(!e) {
      //   this.listQuery.current = 1;
      //   this.listQuery.size = 5;
      //   this.listQuery.adId = ''
      //   this.tableData();
      // }
    },
    onChange(date, dateString) {
      // console.log(date);
      // console.log(dateString);
      this.listQuery.sdate = dateString[0]
      this.listQuery.edate = dateString[1]
      // console.log(this.listQuery);
    },
    handleReset() {
      this.listQuery.appId='';
      this.listQuery.adId='';
      this.adData=[];
    },
    handleSelectChange(value) {
      this.adData=[];
      this.listQuery.adId='';
      listAdApi({"appId":value}).then(response => {
        this.adData = response.body
      })
    },
    checkDelays() {
      if(!this.listQuery.customerId || !this.listQuery.appId){
        this.requestFail('数据异常缺失，无法进行查询');
        return;
      }
      checkDelay(this.listQuery).then(response => {
        // console.log(response)
        this.statusText = response.body.msg
      })
    },
    initTableData(){
      this.reload(true)
      if(this.listQuery.adId){
        this.columns= columns2;
      }else{
        this.columns= columns1;
      }
      // console.info(this.columns)
      this.checkDelays()
      listApi(this.listQuery).then(response => {
        this.data = response.body.records
        this.page.total = response.body.total
        this.reload(false)
      })
    },
    tableData(){
      this.reload(true)
      if(this.listQuery.adId){
        this.columns= columns2;
      }else{
        this.columns= columns1;
      }
      // console.info(this.columns)
      this.checkDelays()
      listSearch(this.listQuery).then(response => {
        this.data = response.body.records
        this.page.total = response.body.total
        this.reload(false)
      })
    },
    affiliatedTableTableChangePage(page,size){
      this.listQuery.current = page;
      this.listQuery.size = size;
      this.tableData();
    },

  },
};
</script>


<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 10px;
}

</style>
